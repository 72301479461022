<template>
  <div class="agr">
    <!-- 导航栏 -->
    <nav-header :href="this.href"></nav-header>

    <div class="container">
      <div class="my-title">
        <h2>用户协议</h2>
      </div>
      <div class="my-con">
        <p style="white-space: normal">一、服务协议的范围</p>
        <p style="white-space: normal"><br /></p>
        <p style="white-space: normal">
          1.1杭州雨画科技有限公司(以下简称“雨画科技”)动漫按照本协议提供基于互联网以及移动网的相关动漫服务（以下称"动漫服务"）。
        </p>
        <p style="white-space: normal"><br /></p>
        <p style="white-space: normal">
          1.2为获得动漫服务，服务使用人（以下称“用户”）
          已审慎和仔细阅读本协议，清楚理解本协议的全部条款和含义，包括免除或者限制雨画科技动漫的免责条款以及对用户的权利限制条款，用户同意并接受本协议的全部条款。除非用户已阅读并接受本协议所有条款，否则用户无权使用雨画科技动漫提供的服务。用户在使用动漫服务及充值动漫保证金时，勾选“同意雨画科技动漫动漫服务协议”勾选框即表示用户完全接受本协议项下的全部条款。用户使用雨画科技动漫的动漫服务视为用户完全同意本协议的条款并受本协议的约束。
        </p>
        <p style="white-space: normal"><br /></p>
        <p style="white-space: normal">
          1.3使用动漫服务的用户帐号和密码由用户负责保管；用户应当对以其用户帐号进行的所有活动、事件、结果负法律责任。
        </p>
        <p style="white-space: normal"><br /></p>
        <p style="white-space: normal">二、服务内容</p>
        <p style="white-space: normal"><br /></p>
        <p style="white-space: normal">
          2.1雨画科技动漫网站动漫服务的具体内容由雨画科技动漫根据现有技术和实际情况提供。
        </p>
        <p style="white-space: normal"><br /></p>
        <p style="white-space: normal">
          2.2鉴于动漫的特殊性，保证用户按国家相关法律法规及雨画科技动漫相关运营规则使用动漫服务，用户必须交纳动漫服务保证金后方可使用雨画科技动漫提供的动漫服务。用户交纳保证金是以充值的形式进行，充值时需要提供实名认证、银行卡帐号、手机号等个人资料并经雨画科技动漫审核通过后方可开通动漫服务。如果用户或雨画科技动漫终止动漫服务，在用户提供的个人资料审核通过的条件下，用户可对未消耗或未扣除的保证金予以提现。
        </p>
        <p style="white-space: normal"><br /></p>
        <p style="white-space: normal">三、服务规则</p>
        <p style="white-space: normal"><br /></p>
        <p style="white-space: normal">
          3.1鉴于动漫服务的特殊性，用户同意雨画科技动漫有权随时变更、中断或终止部分或全部的动漫服务。如变更、中断或终止动漫服务而造成任何损失，雨画科技动漫无需对用户或任何第三方承担任何责任，雨画科技动漫应尽量在变更、中断或终止服务之前事先以公告、邮件或系统弹窗等形式通知用户。
        </p>
        <p style="white-space: normal"><br /></p>
        <p style="white-space: normal">
          3.2雨画科技动漫需要定期或不定期地对提供动漫服务的平台（如互联网网站、移动网络等）或相关的设备进行检修和维护，如因此类情况而造成动漫服务在合理时间内的中断，雨画科技动漫无需为此承担任何责任，但雨画科技动漫应尽量减小因此给用户造成的影响。
        </p>
        <p style="white-space: normal"><br /></p>
        <p style="white-space: normal">
          3.3如发生下列任何一种情形，雨画科技动漫有权随时中断或终止向用户提供本协议项下的动漫服务而无需对用户或任何第三方承担任何责任：
        </p>
        <p style="white-space: normal"><br /></p>
        <p style="white-space: normal">3.3.1用户提供的个人资料不真实；</p>
        <p style="white-space: normal"><br /></p>
        <p style="white-space: normal">
          3.3.2用户违反本协议的约定或违反雨画科技动漫公布的运营规则；
        </p>
        <p style="white-space: normal"><br /></p>
        <p style="white-space: normal">
          3.3.3用户在使用动漫服务时未按规定向雨画科技动漫交纳相应的保证金或补交保证金。
        </p>
        <p style="white-space: normal"><br /></p>

        <p style="white-space: normal">四、使用规则</p>
        <p style="white-space: normal"><br /></p>

        <p style="white-space: normal">
          4.1用户在使用动漫服务时，必须向雨画科技动漫提供准确的用户个人资料，如用户提供资料不实，雨画科技动漫有权拒绝提供动漫服务；如用户个人资料有任何变动，必须及时更新并通知雨画科技动漫进行审核。如因用户提供的个人资料等不实而造成任何损失，由用户自已承担全部责任和损失。
        </p>
        <p style="white-space: normal"><br /></p>
        <p style="white-space: normal">
          4.2用户不得将其账号、密码转让或出借给他人使用。如因黑客行为或用户的保管疏忽导致账号、密码遭他人非法盗取、使用或遭受损失，雨画科技动漫不承担任何责任；如给雨画科技动漫造成损害，则用户应予赔偿。
        </p>
        <p style="white-space: normal"><br /></p>
        <p style="white-space: normal">
          4.3用户同意雨画科技动漫有权在提供动漫服务过程中以各种方式投放商业性广告、非商业性广告、其他任何类型的商业信息和非商业信息，用户必须予以配合，且雨画科技动漫无需要支付任何对价，但应尽量减小给用户造成的影响。
        </p>
        <p style="white-space: normal"><br /></p>
        <p style="white-space: normal">
          4.4用户在使用动漫服务过程中有任何不当行为，或违反法律法规和雨画科技动漫的相关运营规则，或侵犯第三方合法权益，都由用户自行承担相应责任，雨画科技动漫无需要承担任何责任。如因用户的行为而给雨画科技动漫造成损害的，用户应予赔偿。
        </p>
        <p style="white-space: normal"><br /></p>
        <p style="white-space: normal">
          4.5用户在使用动漫服务过程中，严格遵守国家《互联网信息管理办法》、《网络表演经营活动管理办法》等相关法律法规，用户不得制作、复制、发布、传播含有下列内容的信息：
        </p>
        <p style="white-space: normal"><br /></p>
        <p style="white-space: normal">（一）反对宪法所确定的基本原则的；</p>
        <p style="white-space: normal"><br /></p>
        <p style="white-space: normal">
          （二）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；
        </p>
        <p style="white-space: normal"><br /></p>
        <p style="white-space: normal">（三）损害国家荣誉和利益的；</p>
        <p style="white-space: normal"><br /></p>
        <p style="white-space: normal">
          （四）煽动民族仇恨、民族歧视，破坏民族团结的；
        </p>
        <p style="white-space: normal"><br /></p>
        <p style="white-space: normal">
          （五）破坏国家宗教政策，宣扬邪教和封建迷信的；
        </p>
        <p style="white-space: normal"><br /></p>
        <p style="white-space: normal">
          （六）散布谣言，扰乱社会秩序，破坏社会稳定的；
        </p>
        <p style="white-space: normal"><br /></p>
        <p style="white-space: normal">
          （七）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；
        </p>
        <p style="white-space: normal"><br /></p>
        <p style="white-space: normal">
          （八）侮辱或者诽谤他人，侵害他人合法权益的；
        </p>
        <p style="white-space: normal"><br /></p>
        <p style="white-space: normal">
          （九）含有法律、行政法规禁止的其他内容的。
        </p>
        <p style="white-space: normal"><br /></p>
        <p style="white-space: normal">
          （十）有法律、行政法规和国家规定禁止的其他内容的。
        </p>
        <p style="white-space: normal"><br /></p>
        <p style="white-space: normal">
          如用户违反法律法规、本协议约定或雨画科技动漫相关运营规则，用户同意并接受雨画科技动漫根据约定或规则对其交纳的保证金进行相应的扣除并对其采取暂停动漫服务、扣罚佣金等其它处罚措施。
        </p>
        <p style="white-space: normal"><br /></p>
        <p style="white-space: normal">五、免责声明</p>
        <p style="white-space: normal"><br /></p>
        <p style="white-space: normal">
          5.1用户完全根据自身意志和意愿以自身行为使用动漫服务，同意使用雨画科技动漫动漫服务所存在的风险完全由其自己承担；因用户使用动漫服务而产生的一切损害、损失、责任及后果也由其自己承担，雨画科技动漫对用户不承担任何责任。
        </p>
        <p style="white-space: normal"><br /></p>
        <p style="white-space: normal">
          5.2雨画科技动漫根据现有技术条件和实际情况尽量提供符合要求的动漫服务，但并不担保动漫服务一定能满足用户的全部要求，也不担保动漫服务因实际情况出现变更、中断或终止的情形；雨画科技动漫对动漫服务的及时性、安全性、准确性也都不作担保，用户不得就此向雨画科技动漫提供任何要求。
        </p>
        <p style="white-space: normal"><br /></p>
        <p style="white-space: normal">
          5.3对于因不可抗力或雨画科技动漫不能控制的原因造成的动漫服务中断或其它缺陷，雨画科技动漫不承担任何责任，但将尽力减少因此而给用户造成的损失和影响。
        </p>
        <p style="white-space: normal"><br /></p>
        <p style="white-space: normal">
          5.4用户如无法接受本协议相关约定和免责声明，有权自主决定不使用动漫服务或终止使用动漫服务。如接受本协议，应严格遵守相关条款。用户使用动漫服务视为用户接受协议并受协议、运营规则约束。
        </p>
        <p style="white-space: normal"><br /></p>
        <p style="white-space: normal">六、规则和协议的修改</p>
        <p style="white-space: normal"><br /></p>
        <p style="white-space: normal">
          6.1雨画科技动漫有权修改相关运营规则和本协议的条款，一旦运营规则或本协议的内容有修改和变动，雨画科技动漫将会直接在雨画科技动漫旗下经营的网站上公布修改之后的运营规则或协议内容，新的运营规则或协议自公布之日即时生效。该公布行为视为雨画科技动漫已经通知用户修改内容，雨画科技动漫也可通过其他适当方式向用户提示修改内容，用户也可自行到雨画科技动漫相关网站查阅。
        </p>
        <p style="white-space: normal"><br /></p>
        <p style="white-space: normal">
          6.2如果用户不同意雨画科技动漫新的运营规则或本协议内容所做的修改，用户有权停止使用动漫服务。如果用户继续使用动漫服务，则视为用户同意并接受雨画科技动漫的运营规则和雨画科技动漫对本协议相关条款所做的修改。
        </p>
        <p style="white-space: normal"><br /></p>
        <p style="white-space: normal">七、其它约定</p>
        <p style="white-space: normal"><br /></p>
        <p style="white-space: normal">
          7.1如用户和雨画科技动漫之间就协议的签订、履行等产生任何纠纷，双方应友好协商；如协商不成，则应提交人民法院管辖。
        </p>
        <p style="white-space: normal"><br /></p>
        <p style="white-space: normal">
          7.2杭州雨画科技有限公司 动漫服务的所有权、运作权和解释网络所有。
        </p>
        <p><br /></p>
      </div>
    </div>
  </div>
</template>

<script>
import NavHeader from "./../components/NavHeader";
export default {
  name: "agreement",
  components: {
    NavHeader,
  },
  data() {
    return {
      href: "index.html",
    };
  },
  mounted() {
    document.title = "杭州雨画科技有限公司 ";
  },
};
</script>
<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.agr {
  .container {
    text-align: center;
    padding-top: 30px;
    // 标题
    .my-title {
      font-size: 28px;
      font-weight: bold;
    }
    // 主容器
    .my-con {
      margin: 30px auto;
      border: 1px solid #ccc;
      font-size: 18px;
      text-align: left;
      line-height: 40px;
      padding: 20px;
      p {
        margin: 10px auto;
      }
    }
  }
}
</style>
