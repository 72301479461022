<template>
  <div class="index">
    <!-- 导航栏 -->
    <nav-header :href="this.href"></nav-header>
    <div class="container">
      <h2>{{ this.title }}</h2>
      <p>
        <!-- <span>发布作者：{{ this.author }}</span> -->
        <span>发布时间：{{ this.time }}</span>
         
      </p>
      <div class="i-con">
        <a href="javascript:;" @click="gopay()"> <i class="iconfont icon-menzhenshoufei"></i> 付费后观看完整漫画 ></a>

        <img :src="this.img1" />
        <img :src="this.img2" />
        <img :src="this.img3" />
        <!-- <img :src="this.img4" />
        <img :src="this.img5" />
        <img :src="this.img6" /> -->
           <!-- modal 弹框 -->
      <modal
        title="微信扫码支付~"
        btnType="1"
        modalType="middle"
        :showModal="showModal"
        @submit="showModal = false"
        @cancel="showModal = false"
      >
        <template v-slot:body>
          <img src="/imgs/pay.png" />
          <p>收费标准：5元/10章节</p>
        </template>
      </modal>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from "../components/Modal.vue";
import NavHeader from "./../components/NavHeader";
export default {
  data() {
    return {
      showModal: false,
      state: JSON.parse(window.localStorage.getItem("state")),
      href: "index.html",
      // 获取 id
      myId: this.$route.query.type,
      // 获取 标题
      title: "",
      // 简介
      concise: "",
      // 作者
      author: "",
      // 图片路径
      img1: "",
      img2: "",
      img3: "",
      img4: "",
      img5: "",
      img6: "",
      img7: "",
      img8: "",
      img9: "",
      img10: "",
      img11: "",
      img12: "",
      img13: "",
      img14: "",

      time: "",
      ac3: [],
      d1: [],
      d2: [],
      d3: [],
      sub: "",
    };
  },
  components: {
    NavHeader,
    Modal
  },
  mounted() {
    this.init();

    document.title = "杭州雨画科技有限公司";
  },
  methods: {
    init() {
      this.axios.get("/mock/sound.json").then((res) => {
        var vl = res.data;
        var date = vl.find((vl) => vl.id == this.myId);
        console.log(date);
        // console.log(date)
        // 标题
        this.title = date.title;
        // 作者
        this.author = date.author;
        // 歌词
        this.concise = date.concise;
        // 图片路径
        this.img1 = date.imgs1;
        this.img2 = date.imgs2;
        this.img3 = date.imgs3;
        this.img4 = date.imgs4;
        this.img5 = date.imgs5;
        this.img6 = date.imgs6;
        this.img7 = date.imgs7;
        this.img8 = date.imgs8;
        this.img9 = date.imgs9;
        this.img10 = date.imgs10;
        this.img11 = date.imgs11;
        this.img12 = date.imgs12;
        this.img13 = date.imgs13;
        this.img14 = date.imgs14;

        this.time = date.time;
        this.ac3 = vl.slice(0, 7);
        this.sub = date.sub;
      });
    },

    // 跳转功能
    goDays(methodsWords, id) {
      this[methodsWords](id);
    },
    // 跳转详情页
    goDetails(id) {
      this.$router.push({
        path: "/sound/:" + id,
        query: { type: id },
      });
      location.reload();
    },
    // 收费逻辑
    gopay() {
    
      // console.log('1')

      if (this.state == 0) {
        // this.$router.push("/pay");
        this.showModal = true;
        console.log(this.showModal);
      }
      // 未登录
      if (this.state !== 0) {
        alert("请登录后付费~");
        this.$router.push({ path: "/login", query: { k: 1 } });
        return;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.index {
  background-color: #eee;
 
  .container {
    text-align: center;
    margin-top: 60px;
     p {
      width: 100%;
      text-align: center;
      margin: 30px 0;
      span {
        font-size: 14px;
        margin: 0 15px;
      }
    }
    // 主容器1
    .i-con {
      width: 80%;
      text-align: center;
      margin: 15px auto;
      img {
        width: 100%;
        display: block;
        margin: 0 auto;
        text-align: center;
      }

      a {
          display: inline-block;
          margin-top: 20px;
          // width: 100%;
          font-size: 28px;
          color: #333;
          font-weight: bold;
          cursor: pointer;
          transition: all .3s;
          text-decoration: none;
          margin-bottom: 15px;
          i {
            font-size: 28px;
            // margin-right: 5px;
          }
          &:hover {
              color: #ff6600;
          }
      }
    }
  }
}
</style>
