<template>
  <div>
    <!-- 导航栏 -->
    <nav-header :href="this.href"></nav-header>
    <div id="wrapper" class="login-page">
      <div id="login_form" class="form">
        <form class="register-form">
          <input type="text" placeholder="用户名" id="r_user_name" v-model="rUsername" />
          <input type="password" placeholder="密码" id="r_password" v-model="rPassword" />

          <input type="text" placeholder="请输入姓名" id="r_password" />

          <input type="text" placeholder="请输入手机号码" id="r_password" />
          <input type="text" placeholder="请输入身份证号码" id="r_password" />

          <button id="create" @click="register()" type="text">注册账户</button>
          <p class="message" @click="fnc1()">
            > 已经有了一个账户? <a href="#">立刻登录</a>
          </p>
          <p class="message" @click="fnc1()">
            <a href="/#/xy">用户协议</a>
          </p>
          <p style="margin-top: 5px; font-size: 12px; color: #888">
            未成年人登录后只有周末时间能上线4个小时
          </p>
        </form>
        <form class="login-form">
          <input type="text" placeholder="用户名" id="user_name" v-model="lUsername" />
          <input type="password" placeholder="密码" id="password" v-model="lPassword" />
          <button id="login" @click="logo()" type="text">登录</button>
          <p class="message" @click="fnc1()">还没有账户? <a href="#">立刻注册</a></p>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import NavHeader from "./../components/NavHeader";
export default {
  data() {
    return {
      href: "index.html",
      // 用户名
      lUsername: "",
      // 密码
      lPassword: "",
      // 获取收费跳转参数
      k: this.$route.query.k,
      // 获取到 user 用户信息
      logo_user: JSON.parse(window.localStorage.getItem("user")),
      // 获取到 sor 的用户信息
      logo_user2: JSON.parse(window.sessionStorage.getItem("user")),
      rUsername: "",
      rPassword: "",
    };
  },
  methods: {
    fnc1() {
      $("form").animate(
        {
          height: "toggle",
          opacity: "toggle",
        },
        "slow"
      );
    },
    // 登录逻辑
    logo() {
      // 预设用户
      var custom_user = {
        Username: "mxw",
        Password: "mxw",
        rPhone: "123",
      };

      // 如果是预设用户
      if (this.lUsername == "mxw") {
        if (this.lPassword == "mxw") {
          window.sessionStorage.setItem("user", JSON.stringify(custom_user));
        } else {
          alert("密码错误");
          return;
        }
      }

      // 不是预设用户
      if (this.lUsername != "mxw") {
        custom_user = {
          Username: this.lUsername,
          Password: this.lPassword,
          rPhone: "123",
        };
        window.sessionStorage.setItem("user", JSON.stringify(custom_user));
      }
      window.localStorage.setItem("state", "0");
      this.$router.push("/");
      window.location.reload();

      // if (this.lUsername !== this.logo_user.Username) {
      //     alert("用户名错误");
      //     return;
      //   } else if (this.lPassword !== this.logo_user.Password) {
      //     alert("密码错误");
      //     return;
      //   } else  {
      //     window.localStorage.setItem("state", "0");
      //     this.$router.push("/");
      //     window.location.reload();
      //   }
    },
    // 注册逻辑
    register() {
      // 获取 user
      var ruser = {
        Username: this.rUsername,
        Password: this.rPassword,
      };
      // 判断非空
      if (!this.rUsername) {
        alert("请输入用户名");
        return;
      }
      if (!this.rPassword) {
        alert("请输入密码");
        return;
      } else {
        window.localStorage.setItem("state", "0");
        window.localStorage.setItem("user", JSON.stringify(ruser));
        this.$router.push("/");
        window.location.reload();
      }
    },
    // 跳转协议
    Xy() {
      this.$router.push("/xy");
    },
  },

  components: {
    NavHeader,
  },
  mounted() {
    document.title = "杭州雨画科技有限公司";
  },
};
</script>
<style lang="scss" scoped>
.login-page {
  // width: 360px;
  width: 100%;
  padding: 5% 0 0;
  margin: auto;
  padding-bottom: 60px;
  box-sizing: border-box;
  // background-color: pink;
  background: url('/imgs/ht.jpg') no-repeat ;
  // background-size: cover;
}
.form {
  position: relative;
  z-index: 1;
  background: #ffffff;
  max-width: 360px;
  margin: 0 auto 0;
  padding: 45px;
  text-align: center;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}
.form input {
  font-family: "Roboto", sans-serif;
  outline: 0;
  background: #f2f2f2;
  width: 100%;
  border: 0;
  margin: 0 0 15px;
  padding: 15px;
  box-sizing: border-box;
  font-size: 14px;
}
.form button {
  font-family: "Microsoft YaHei", "Roboto", sans-serif;
  text-transform: uppercase;
  outline: 0;
  background: #252d45;
  width: 100%;
  border: 0;
  padding: 15px;
  color: #ffffff;
  font-size: 14px;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: pointer;
}
.form button:hover,
.form button:active,
.form button:focus {
  background: #252d45;
}
.form .message {
  margin: 15px 0 0;
  color: #b3b3b3;
  font-size: 12px;
}
.form .message a {
  color: #252d45;
  text-decoration: none;
}
.form .register-form {
  display: none;
}
.container {
  position: relative;
  z-index: 1;
  max-width: 300px;
  margin: 0 auto;
}
.container:before,
.container:after {
  content: "";
  display: block;
  clear: both;
}
.container .info {
  margin: 50px auto;
  text-align: center;
}
.container .info h1 {
  margin: 0 0 15px;
  padding: 0;
  font-size: 36px;
  font-weight: 300;
  color: #1a1a1a;
}
.container .info span {
  color: #4d4d4d;
  font-size: 12px;
}
.container .info span a {
  color: #000000;
  text-decoration: none;
}
.container .info span .fa {
  color: #ef3b3a;
}
</style>
