<template>
  <div class="nav-header">
    <!-- 上层 st -->
    <div class="n-t">
      <div class="w">
        <span>欢迎访问杭州雨画科技有限公司网站~</span>
        <span> <i class="iconfont icon-dianhua_o"></i> 18668050159 </span>
      </div>
    </div>
    <!-- 上层 end -->

    <!-- 下层 st -->
    <div class="n-b">
      <div class="w">
        <!-- logo -->
        <div class="n-logo">
          <img src="/imgs/logo.png" alt="" />
        </div>

        <!-- 导航列表 -->
        <div class="n-list">
          <a href="/" class="active">网站首页</a>
          <a href="/#/info">关于我们</a>
          <a href="/#/xy2">未成年人限制条款</a>
          <a href="/#/xy">用户协议</a>
          <a href="/#/xy3">交易纠纷处理</a>
          <a href="javascript:;" @click="goLogin()" v-show="this.LoggedShow"
            >登录 / 注册</a
          >
          <!-- <a href="javascript:;" @click="goRegister()" v-show="this.LoggedShow">注册</a> -->
          <a href="javascript:;" v-show="!this.LoggedShow" v-text="this.uname"></a>
          <a href="javascript:;" @click="tolg()" v-show="!this.LoggedShow">退出登录</a>
        </div>
      </div>
    </div>
    <!-- 下层 end -->
  </div>
</template>

<script>
export default {
  components: {},
  name: "nav-header",
  props: {
    href: String,
  },
  data() {
    return {
      uname: "",
      // 按钮显示/隐藏
      LoggedShow: true,
      // 获取到 user 用户信息
      nv_user: "",
      // 存储状态
      state: JSON.parse(window.localStorage.getItem("state")),
    };
  },
  mounted() {
    var user = window.sessionStorage.getItem("user");
    if (user) {
      this.nv_user = JSON.parse(user);
      this.uname = this.nv_user.Username;
    }

    this.logged();
  },
  methods: {
    // 登录时隐藏逻辑
    logged() {
      if (this.nv_user) {
        this.LoggedShow = false;
      }
    },
    // 登录跳转
    goLogin() {
      this.$router.push("/login");
    },
    // 注册跳转
    goRegister() {
      this.$router.push("/register");
    },
    // 退出登录
    tolg() {
      window.sessionStorage.removeItem("user");
      window.localStorage.setItem("state", "10");
      this.$router.push("/index");
      this.LoggedShow = true;
      // localStorage.clear();
      location.reload();

      // this.$forceUpdate()
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.nav-header {
  width: 100%;

  // 上层
  .n-t {
    background: rgb(242, 242, 242);
    width: 100%;
    .w {
      height: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        cursor: pointer;
        font-size: 14px;
        &:nth-child(2) {
          font-size: 16px;
          &:hover {
            color: $colorZ;
          }
          i {
            font-size: 18px;
            font-weight: bold;
          }
        }
      }
    }
  }

  // 下层
  .n-b {
    background-color: #fff;
    margin-bottom: 20px;
    .w {
      height: 100px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // logo
      .n-logo {
        width: 200px;
        height: 80px;
        img {
          width: 100%;
          height: 100%;
        }
      }

      // 导航
      .n-list {
        a {
          width: 115px;
          height: 50px;
          line-height: 50px;
          text-align: center;
          border-bottom: 2px solid rgb(242, 242, 242);
          display: inline-block;
          margin: 0 15px;
          text-decoration: none;
          font-size: 14px;
          color: $colorZ;
          &:hover {
            color: $colorZ;
            border-bottom: 2px solid #ccc;
          }
        }
      }
    }
  }
}
</style>
