<template>
  <div class="index">
    <!-- 导航栏 -->
    <nav-header :href="this.href"></nav-header>

 

    <!-- banner st -->
    <div class="banner">
      <div class="w">
        <div class="l">
          <img src="/imgs/i1.jpg" alt="" />
        </div>
        <div class="z">
          <img src="/imgs/i2.jpg" alt="" />
          <img src="/imgs/i3.jpg" alt="" />
        </div>
        <div class="r">
          <img src="/imgs/i4.jpg" alt="" />
        </div>
      </div>
    </div>
    <!-- banner end -->

    <!-- 动漫展示 st -->
    <div class="cm">
      <!-- 标题 st -->
      <div class="title">
        <div class="w">
          动漫展示
          <span></span>
        </div>
      </div>
      <!-- 标题 end -->

      <!-- 容器 st -->
      <div class="cm-con">
        <div class="w">
          <!-- 左侧列表展示 -->
          <div class="l">
            <!-- 标题 -->
            <div class="l-title">
              <span>动漫展示</span>
              <p>Animation display</p>
            </div>

            <!-- 列表 -->
            <div class="l-list" v-for="(item, index) in this.dataList"
              :key="index"
              @click="goDays(item.methods, item.id)" >
              <h2><a href="javascript:;">{{item.title}}</a></h2>
              <p>
                {{item.jj}}
              </p>
            </div>

       
          </div>
          <!-- 右侧动漫展示 -->
          <div class="r">
            <!-- 循环项 -->
            <div
              class="r-item"
              v-for="(item, index) in this.dataList"
              :key="index"
              @click="goDays(item.methods, item.id)"
            >
              <!-- 图片 -->
              <div class="item-img">
                <img :src="item.img" alt="" />
              </div>

              <p>{{ item.title }} <span>收费观看：5元</span></p>
            </div>
          </div>
        </div>
      </div>
      <!-- 容器 end -->
    </div>
    <!-- 动漫展示 end -->

     <!-- swiper 轮播 -->
    <div class="swiper" >
      <!-- <img src="/imgs/banner.jpg" alt="" /> -->
      <div class="swiper-box">
        <swiper :options="swiperOption">
          <!-- 循环想 -->
          <swiper-slide v-for="(item, index) in this.swiperList" :key="index">
            <a href="javascript:;"><img :src="item.img" /></a>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
         
        </swiper>
      </div>
    </div>


    <!-- 关于我们 st -->
    <div class="us">
      <div class="title">
        <div class="w">
          关于我们
          <span></span>
        </div>
      </div>

      <!-- 容器 -->
      <div class="us-con">
        <div class="w">
          <div class="l">
            <img src="/imgs/us.jpg" alt="">
           
          </div>
          <div class="r">
             <h4>杭州雨画科技有限公司</h4>
            <p>
              杭州雨画科技有限公司成立于2018年03月30日，注册地位于浙江省杭州市钱塘区白杨街道18号大街（东）128号2幢3楼西面办公室337室，经营范围包括技术开发、技术服务、技术咨询、成果转让：网络信息技术、电子产品、计算机软硬件；服务：企业营销策划，图文设计，企业形象策划，广告设计，制作、代理、发布（除网络广告发布），经营性互联网文化服务，文化艺术交流活动策划（除演出及演出中介），市场营销策划，教育信息咨询（不含出国留学和中介服务），成年人的非证书劳动职业技能培训，经济信息咨询（除商品中介）；食品销售；网上批发、零售：工艺美术品、文化用品、办公用品、音响器材、体育用品、化妆品（除分装）、家具、服饰、母婴用品（除奶粉）、包装材料、电子产品（除国家专控）、数码产品、日用百货、通讯器材（除国家专控）、计算机软硬件。（依法须经批准的项目，经相关部门批准后方可开展经营活动）
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- 关于我们 end -->

    <!-- 分页器 -->
    <navigation
      :pages="pages"
      :current.sync="pageNo"
      @navpage="msgListView"
      style="text-align: center; display: none"
    ></navigation>
    <!-- modal 弹框 -->
    <modal
      title="图片"
      btnType="1"
      modalType="middle"
      :showModal="showModal"
      @submit="showModal = false"
      @cancel="showModal = false"
    >
      <template v-slot:body>
        <img :src="imgUrl" />
      </template>
    </modal>
  </div>
</template>

<script>
import Modal from "../components/Modal.vue";
import NavHeader from "./../components/NavHeader";
import navigation from "./../components/navigation";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
export default {
  name: "index",
  data() {
    return {
      state: JSON.parse(window.localStorage.getItem("state")),
      nv_user: "",
      nv_user2: JSON.parse(window.sessionStorage.getItem("user")),
      href: "/",
      // 根数据
      dataList: [],
      dataList2: [],
      dataList3: [],
      // 总页数
      pages: 7,
      // 当前页
      pageNo: 1,
      // 点击页数
      curPage: 1,
      // 根据页数获取数据
      my_data: [],
      my_data2: [],
      my_data3: [],
      // 是否显示弹框
      showModal: false,

      imgUrl: "",
      // 轮播配置
      swiperOption: {
        autoplay: true,
        loop: true,
        effect: "fade",
        cubeEffect: {
          shadowOffset: 100,
          shadowScale: 0.6,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      // 轮播数组
      swiperList: [
        {
          id: "1",
          img: "/imgs/s1.jpg",
        },
        {
          id: "2",
          img: "/imgs/s2.jpg",
        },
      ],
    };
  },
  components: {
    NavHeader,
    navigation,
    Modal,
    Swiper,
    SwiperSlide,
  },
  mounted() {
    var user = window.sessionStorage.getItem("user");
    if (user) {
      this.nv_user = JSON.parse(user);
      if (this.nv_user.Username == "mxw") this.init2();
      if (this.nv_user.Username != "mxw") this.init();
    } else {
      this.init();
    }

    // alert(this.nv_user.uname)
    // if (this.nv_user.Username == "mxw") {
    //   this.init2();
    // } else {
    //   this.init();
    // }

    document.title = "杭州雨画科技有限公司";
  },
  methods: {
    // 分页器事件 //根据当前页获取数据
    msgListView(curPage) {
      this.curPage = curPage;
      console.log(this.curPage);
      this.init();
    },

    init() {
      // 渲染数据
      this.axios.get("/mock/a1.json").then((res) => {
        console.log(res);
        var data = res.data;
        // 根数据
        this.dataList = data;
      });
    },

    init2() {
      // 渲染数据
      this.axios.get("/mock/a2.json").then((res) => {
        console.log(res);
        var data = res.data;
        // 根数据
        this.dataList = data;
      });
    },
    // 跳转 逻辑 封装
    goDays(methodsWords, type) {
      this[methodsWords](type);
    },
    // 跳转详情页
    goDetails(id) {
      this.$router.push({
        path: "/sound/:" + 1,
        query: { type: id },
      });
    },
    // 收费逻辑
    gopay(id) {
      // if (this.state == 0) {
      //   this.$router.push("/pay");
      //   return;
      // }
      // // 未登录
      // if (this.state !== 0) {
      //   alert("点击登录去购买~");
      //   this.$router.push({ path: "/login", query: { k: 1 } });
      //   return;
      // }

      this.$router.push({
        path: "/sound2/:" + 1,
        query: { type: id },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
@import url("http://www.jq22.com/jquery/bootstrap-3.3.4.css");

.index {
  // swiper 轮播 区块
  .swiper {
    width: 100%;
    // height: 500px;
    margin: 30px 0;
  }

  // bannr
  .banner {
    width: 100%;
    height: 300px;
    margin: 30px 0;
    .w {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      .l {
        width: 40%;
        height: 100%;
        transition: all .3s;
        cursor: pointer;
        &:hover {
          transform: translateX(-10px);
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
      .z {
        width: 35%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        img {
          width: 100%;
          height: 150px;
          cursor: pointer;
          &:nth-child(1) {
            transition: all .3s;
            &:hover {
              transform: translateY(-10px);
            }
          }

          &:nth-child(2) {
            transition: all .3s;
            &:hover {
              transform: translateY(10px);
            }
          }
        }
      }
      .r {
        width: 25%;
        height: 100%;
        cursor: pointer;
        transition: all .3s;
        &:hover {
          transform: translateX(10px);
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  // 动漫展示
  .cm {
    width: 100%;

    // 标题
    .title {
      width: 100%;
      height: 180px;
      background: url("/imgs/cp_bg.jpg") no-repeat center;
      background-size: cover;
      &:hover {
        .w {
          transform: translateY(-20px);
        }
      }
      .w {
        text-align: center;
        line-height: 180px;
        font-size: 31px;
        font-weight: bold;
        color: #fff;
        cursor: pointer;
        position: relative;
        transition: all 0.3s;
      }

      span {
        display: block;
        width: 120px;
        height: 4px;
        background: #fff;
        position: absolute;
        bottom: 28%;
        left: 45%;
      }
    }

    // 容器
    .cm-con {
      width: 100%;
      height: 700px;
      // margin-top: 20px;
      background-color: #eaeaea;
      padding-top: 20px;
      box-sizing: border-box;
      .w {
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .l {
          width: 27%;
          height: 100%;
          background: #fff;
          .l-title {
            width: 100%;
            height: 90px;
            background-color: $colorZ;
            text-align: center;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            cursor: pointer;
            span {
              width: 100%;
              font-size: 18px;
              font-weight: bold;
              color: #fff;
            }
            p {
              width: 100%;
              font-size: 14px;
              color: #fff;
              margin-top: -20px;
            }
          }

          .l-list {
            width: 100%;
            text-align: center;

            margin: 15px 0 35px;
            h2 {
              font-size: 16px;
              width: 200px;
              height: 46px;
              line-height: 46px;
              margin: 0 auto;
              background: url("/imgs/cp_libg.gif") no-repeat center;
              background-size: cover;
              cursor: pointer;
              &:hover {
                a {
                  color: $colorZ;
                }
              }
              a {
                text-decoration: none;
                color: #333;
                transition: all 0.3s;
              }
            }

            p {
              width: 80%;
              margin: 0 auto;
              font-size: 12px;
              color: #888;
            }
          }
        }

        .r {
          width: 65%;
          height: 100%;
          // background-color: red;
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;

          .r-item {
            width: 250px;
            height: 330px;
            background: #fff;
            cursor: pointer;
            margin: 1px 7px;
            transition: all 0.3s;
            &:hover {
              transform: translateY(-10px);
            }
            .item-img {
              width: 250px;
              height: 300px;
              img {
                width: 100%;
                height: 100%;
              }
            }
            p {
              width: 100%;
              height: 30px;
              text-align: center;
              line-height: 30px;
              font-size: 14px;
              color: #333;
              span {
                font-size: 12px;
                font-weight: bold;
                color: #c60023;
              }
            }
          }
        }
      }
    }
  }

  // 关于我们
  .us {
    width: 100%;
    // margin: 40px 0;
    background: #eaeaea;
    padding-bottom: 20px;
    box-sizing: border-box;
    // 标题
    .title {
      width: 100%;
      height: 180px;
      // background: url('/imgs/cp_bg.jpg') no-repeat center;
      background-size: cover;
      &:hover {
        .w {
          transform: translateY(-20px);
        }
      }
      .w {
        text-align: center;
        line-height: 180px;
        font-size: 31px;
        font-weight: bold;
        color: $colorZ;
        cursor: pointer;
        position: relative;
        transition: all 0.3s;
      }
      span {
        display: block;
        width: 120px;
        height: 4px;
        background: $colorZ;
        position: absolute;
        bottom: 28%;
        left: 45%;
      }
    }

    .us-con {
      width: 100%;
      .w {
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .l {
          width: 600px;
          height: 100%;
          background-color: pink;
        }
        .r {
          flex: 1;
          height: 100%;
          background-color: #fff;
          padding-left: 30px;
          padding-top: 20px;
          box-sizing: border-box;
          h4 {
            font-size: 28px;
            color: #333;
            margin: 20px 0;
          }

          p {
            font-size: 14px;
            color: #888;
            line-height: 31px;
            text-indent: 20px;
            text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 7;
  line-clamp: 7;
  -webkit-box-orient: vertical;
          }
        }
      }
    }
  }
}
</style>
